////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useState} from 'react';
import {DatePicker} from "@adsk/alloy-react-date-picker";
import Selector from './Selector';
import {DefaultItem} from '@adsk/alloy-react-dropdown';
import {Task} from '../dataModel/Task';
import {TaskTrigger} from '../Enums';
import {FlexColumn, FlexRow} from '../CommonStyledComponents';
import {ConvertAutodeskSchedule} from '../converters/ConvertAutodeskSchedule';
import {ConvertTrigger} from '../converters/ConvertTrigger';
import {RecurrenceSettings} from '../dataModel/RecurrenceSettings';
import Theme from "@adsk/alloy-react-theme";
import Schedule from '@adsk/alloy-react-schedule';
import TimePicker from "@adsk/alloy-react-time-picker";

const SettingsTrigger = ({
                           task,
                           onChanged
                         }: { task: Task | undefined, onChanged?: (trigger: TaskTrigger) => void }) => {
  const convertedSchedule = task?.RecurrenceSettings == null
    ? undefined
    : ConvertAutodeskSchedule.GetAutodeskSchedule(task.RecurrenceSettings);
  const [trigger, setTrigger] = useState(task?.Trigger);
  const [schedule] = useState(convertedSchedule);
  const [startDate, setStartDate] = useState(task?.StartDate);
  const [showDays, setShowDays] = useState(task?.RecurrenceSettings?.Recurrence !== 'Weekly');

  const options: DefaultItem[] = [
    {value: 'OnceNow', label: ConvertTrigger.Convert('OnceNow')},
    {value: 'OnceLater', label: ConvertTrigger.Convert('OnceLater')},
    {value: 'Recurring', label: ConvertTrigger.Convert('Recurring')},
    {value: 'OnPublish', label: ConvertTrigger.Convert('OnPublish')},
  ];

  function triggerChange(value: DefaultItem | null | undefined): void {
    if (task == null || value == null) {
      return;
    }
    task.Trigger = value.value as TaskTrigger;

    switch (task.Trigger) {
      case 'OnceLater':
      case 'Recurring':
        const date = new Date();
        date.setHours(date.getHours() + 1, 0, 0, 0);
        task.StartDate = date;
        task.RecurrenceSettings = new RecurrenceSettings(date, 99999);
        setStartDate(date);
        break;
    }

    setTrigger(task.Trigger);

    if (onChanged) {
      onChanged(task.Trigger);
    }
  }

  function scheduleChange(e: any): void {
    if (task == null) {
      return;
    }
    task.RecurrenceSettings = ConvertAutodeskSchedule.GetUiSchedule(e);
    setShowDays(task.RecurrenceSettings?.Recurrence !== 'Weekly');
  }

  function timeChange(hours: number, minutes: number): void {
    if (task?.StartDate == null) {
      return;
    }
    task.StartDate = new Date(task.StartDate.setHours(hours, minutes, 0, 0));
  }

  function dateChange(newDate: Date | null | undefined): void {
    if (task?.StartDate == null || newDate == null) {
      return;
    }

    // Set the values here instead of just taking the full date to avoid blanking the time setting.
    task.StartDate = new Date(task.StartDate.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()));
    // Need to have this as a separate state variable, or it doesn't want to update properly.
    setStartDate(task.StartDate);
  }

  return (
    <div>
      <h2 style={Theme.typography.heading2}>Trigger</h2>
      <FlexRow>
        <div style={{flex: 1}}>
          <Selector
            items={options}
            selected={options.find(o => o.value === trigger) ?? options[0]}
            onSelectionChange={triggerChange}/>
        </div>
        <div style={{flex: 3, marginLeft: '2em'}}>
          {
            task?.Trigger === 'OnceLater' &&
            <FlexRow>
              <FlexColumn>
                <span style={Theme.typography.labelSmall}>Run on</span>
                <DatePicker
                  value={startDate}
                  disablePastDates={true}
                  onChange={e => dateChange(e.date)}/>
              </FlexColumn>
              <FlexColumn>
                <span style={Theme.typography.labelSmall}>Run at</span>
                <TimePicker value={{hours: startDate?.getHours() ?? 0, minutes: startDate?.getMinutes() ?? 0}}
                            onChange={t => timeChange(t?.hours ?? 0, t?.minutes ?? 0)}/>
              </FlexColumn>
            </FlexRow>
          }
          {
            task?.Trigger === 'Recurring' &&
            <Schedule onChange={scheduleChange} data={schedule} hideStepper={!showDays}/>
          }
        </div>
      </FlexRow>
    </div>
  );
};

export default SettingsTrigger;